import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Toolbar, IconButton, AppBar } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import logo from "./assets/logo.png";
import LogoutButton from "./components/logout-button";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
  })
);

export default function Header() {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar} color="primary">
      <Toolbar>
        <NavLink to="/">
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img src={logo} alt="logo" />
          </IconButton>
        </NavLink>
        <LogoutButton />
      </Toolbar>
    </AppBar>
  );
}
