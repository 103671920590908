import gql from "graphql-tag";

export const CANDIDATE_FRAGMENT = gql`
  fragment Candidate on CandidateVersion {
    id
    email
    name
    surname
    status
    phoneNumber
    educationStatus
    recruitmentStatus
    school
    note
    githubUrl
    acceptedConsents {
      id
      tag
      wording
      header
      order
      isRequired
    }
    foundOutFrom
    position
    createdAt
    version
    isConfirmed
    withNewApplication
    createdFromIp
    confirmedFromIp
    confirmedAt
    applicationFileId
    ttl
    signedLink
    availabilityDate
    recruitmentProcessHistory {
      createdAt
      recruiterNote
      recruitmentStatus
      author
    }
  }
`;
