import { IMessage } from "./reduxTypes";
import { MessageActions } from "./actionTypes";

export function addMessage(payload: Omit<IMessage, "id">) {
  return {
    type: MessageActions.ADD_MESSAGE,
    payload: {
      ...payload,
      id: String(new Date().getTime()),
    },
  };
}
export function deleteMessage(id: string) {
  return {
    type: MessageActions.DELETE_MESSAGE,
    payload: { id },
  };
}
export function clearQueue() {
  return {
    type: MessageActions.CLEAR_QUEUE,
  };
}
