import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearQueue, deleteMessage } from "./redux/actions";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export interface IMessage {
  severity: AlertProps["severity"];
  message: string;
  id: string;
}

export interface IToastProps {
  messages: IMessage[];
  handleClose: (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => void;
  handleCloseItem: (id: string) => void;
}

export default function Toast({ messages, handleClose, handleCloseItem }: IToastProps) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={!!messages.length}
      onClose={handleClose}
      autoHideDuration={6000}
    >
      <Box display='flex' flexDirection='column' flexWrap='nowrap'>
        {messages.map((message) => (
          <Alert key={message.id} onClose={() => handleCloseItem(message.id)} severity={message.severity}>
            {message.message}
          </Alert>
        ))}
      </Box>
    </Snackbar>
  );
}

export type WithToastProps = {
  addMessage: (message: Omit<IMessage, "id">) => void;
};

export function withToast(Component: any) {
  return (props: any) => {
    const [messages, setMessages] = useState<IMessage[]>([]);

    function handleCloseItem(id: string) {
      const messageIdxToClose = messages.findIndex((message) => message.id === id);
      if (messageIdxToClose !== -1) {
        messages.splice(messageIdxToClose, 1);
        setMessages([...messages]);
      }
    }

    function handleClose(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
      if (reason === "clickaway") {
        return;
      }
      setMessages([]);
    }

    function handleAddMessage(newMessage: Omit<IMessage, "id">) {
      const newMessageWithId = { ...newMessage, id: String(new Date().getTime()) } as IMessage;
      const newMessages = [...messages, newMessageWithId];
      setMessages(newMessages);
    }

    return (
      <>
        {!!messages.length && <Toast handleClose={handleClose} handleCloseItem={handleCloseItem} messages={messages} />}
        <Component addMessage={handleAddMessage} {...props} />
      </>
    );
  };
}

export function ToastRedux() {
  // @ts-ignore
  const messages = useSelector((state) => state.messages);
  const dispatch = useDispatch();
  function handleClose() {
    dispatch(clearQueue());
  }
  function handleCloseItem(id: string) {
    dispatch(deleteMessage(id));
  }
  return <Toast messages={messages} handleClose={handleClose} handleCloseItem={handleCloseItem} />;
}
