import React from "react";
import { Typography } from "@material-ui/core";
import { Routes } from "../../routes";
import { StyledContainer } from "../../components/StyledContainer";
import { InlineWrapper } from "../../components/InlineWrapper";
import Candidate from "../../components/Candidate";
import { useHistory } from "react-router-dom";
import { HeadCellProps } from "../../components/TableLabels";
import TableView from "../../components/TableView";
import CustomizedProgressBar from "../../components/Loader";
import CandidatesFilter from "../../components/CandidatesFilter";
import useCandidatesQuery from "./useCandidatesQuery";
import usePagination from "../../utils/pagination";

const initialOrder = "confirmedAt";
const initialSortingOrder = "desc";
export const Candidates: React.FC = (props) => {
  const { rowsPerPage, page, ...restProps } = usePagination(
    initialOrder,
    initialSortingOrder
  );

  const {
    loading,
    candidates,
    countCandidates: totalRows,
    error
  } = useCandidatesQuery({
    page,
    rowsPerPage,
    order: restProps.order,
    orderBy: restProps.orderBy,
    filter: restProps.filter
  });

  const { push, location } = useHistory();

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 20,
          color: "rgb(255, 36, 24)",
          marginTop: 50
        }}
      >
        {error.message || "Unexpected Error"}
      </div>
    );
  }

  const headCells: HeadCellProps[] = [
    { id: "name", label: "Name", isSortable: true, align: "inherit" },
    { id: "surname", label: "Surname", isSortable: true },
    { id: "cv", label: "CV", isSortable: false },
    { id: "position", label: "Position", isSortable: true },
    { id: "status", label: "Status", isSortable: true },
    { id: "confirmedAt", label: "Date", isSortable: true }
  ];

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    push(`/${Routes.Candidate}/` + id, { search: location.search });
  };

  return (
    <StyledContainer>
      <CandidatesFilter
        onChangeFilter={restProps.onChangeFilter}
        filter={restProps.filter}
      />
      <InlineWrapper>
        <Typography variant="h5" gutterBottom>
          List of applications{" "}
          <span style={{ fontSize: 15, color: "rgba(0,0,0,.5)" }}>
            ({totalRows})
          </span>
        </Typography>
      </InlineWrapper>
      {loading || !candidates ? (
        <CustomizedProgressBar />
      ) : (
        <TableView
          headCells={headCells}
          totalRowsQuantity={totalRows}
          tableBody={
            candidates &&
            candidates.map((candidate) => (
              <Candidate
                key={candidate.id}
                id={candidate.id}
                name={candidate.name}
                surname={candidate.surname}
                signedLink={candidate.signedLink}
                position={candidate.position}
                status={candidate.recruitmentStatus}
                confirmedAt={candidate.confirmedAt}
                handleClick={handleClick}
              />
            ))
          }
          page={page}
          rowsPerPage={rowsPerPage}
          {...props}
          {...restProps}
        />
      )}
    </StyledContainer>
  );
};

export default Candidates;
