// @ts-nocheck
import { MessageActions } from "./actionTypes";
import { IMessage } from "./reduxTypes";

const initialState: IMessage = [];

export default function messagesReducer(state = initialState, action) {
  switch (action.type) {
    case MessageActions.ADD_MESSAGE: {
      return [...state, action.payload];
    }
    case MessageActions.DELETE_MESSAGE: {
      const messageIdxToClose = state.findIndex((message) => message.id === action.payload.id);
      if (messageIdxToClose !== -1) {
        state.splice(messageIdxToClose, 1);
      }
      return [...state];
    }
    case MessageActions.CLEAR_QUEUE: {
      return [];
    }
    default:
      return state;
  }
}
