import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { PaginationProps } from "../../utils/pagination";
import { CandidateVersion } from "./types";

const GET_CANDIDATES = gql`
  query (
    $filter: CandidateVersionsFilterInput
    $order: CandidateVersionsOrderInput
    $pagination: PaginationInput
  ) {
    countCandidateVersions(filter: $filter)
    candidateVersions(filter: $filter, order: $order, pagination: $pagination) {
      id
      email
      name
      surname
      status
      phoneNumber
      educationStatus
      school
      note
      githubUrl
      acceptedConsents {
        id
        tag
        wording
        header
        order
        isRequired
      }
      foundOutFrom
      position
      createdAt
      version
      isConfirmed
      withNewApplication
      createdFromIp
      confirmedFromIp
      confirmedAt
      applicationFileId
      signedLink
      ttl
      recruitmentStatus
    }
  }
`;

interface ICandidatesData {
  candidateVersions: CandidateVersion[];
  countCandidateVersions?: number;
  loading?: boolean;
}

type UseCandidatesProps = Pick<
  PaginationProps,
  "page" | "rowsPerPage" | "order" | "orderBy" | "filter"
>;

function useCandidates(props: UseCandidatesProps) {
  const { loading, error, data } = useQuery<ICandidatesData, {}>(
    GET_CANDIDATES,
    {
      variables: {
        order: {
          orderBy: props.orderBy,
          order: props.order
        },
        pagination: {
          offset: props.rowsPerPage * props.page,
          first: props.rowsPerPage
        },
        filter: {
          nameSurname: props.filter.nameSurname,
          recruitmentStatus: props.filter.status,
          confirmedAt: props.filter.confirmedAt
        }
      },
      fetchPolicy: "cache-and-network"
    }
  );

  const countCandidates = data?.countCandidateVersions ?? 0;
  const candidates = data?.candidateVersions ?? [];
  return { loading, candidates, countCandidates, error };
}

export default useCandidates;
