import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import DescriptionIcon from "@material-ui/icons/Description";
import EnvelopeIcon from "@material-ui/icons/MailOutline";
import WarningIcon from "@material-ui/icons/Warning";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Filter1Icon from "@material-ui/icons/Filter1";
import Filter2Icon from "@material-ui/icons/Filter2";
import Filter3Icon from "@material-ui/icons/Filter3";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import { StyledContainer } from "../../components/StyledContainer";
import { InlineWrapper } from "../../components/InlineWrapper";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { recruitmentStatuses } from "../../constants";
import ApplicationDropdown from "./ApplicationDropdown";

const useStyles = makeStyles({
  styledTableCell: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    minWidth: 150,
  },
  styledTableContainer: {
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
  },
  formControl: {
    minWidth: 182,
  },
});

export const Candidate: React.FC<any> = ({
  candidate,
  formError,
  editCandidateVersion,
  history: {
    location: { state },
  },
}) => {
  const classes = useStyles();
  function setEducationTitle(education) {
    switch (education) {
      case "part_time_student":
        return "Part-Time Student";
      case "graduated":
        return "Degree Completed";
      case "not_studying":
        return "No Degree";
      case "student":
        return "Full-Time Student";
      default:
        return "-";
    }
  }

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return (
        '<a style="color: #38acef" rel="noreferrer" target="_blank" href="' +
        url +
        '">' +
        url +
        "</a>"
      );
    });
  }

  let historyEntryKey = 0;
  const recruitmentStatusInput = React.useRef<HTMLInputElement>(null);
  const recruiterNoteInput = React.useRef<HTMLInputElement>(null);
  const [recruitmentStatus, setRecruitmentStatus] = React.useState(
    candidate.recruitmentStatus
  );
  const [recruiterNote, setRecruiterNote] = React.useState("");

  const handleStatusChange = (event) => {
    setRecruitmentStatus(event.target.value);
  };

  const handleNoteChange = (event) => {
    setRecruiterNote(event.target.value);
  };

  const search = (state && state.search) || "";

  return (
    <StyledContainer>
      <InlineWrapper>
        <NavLink
          to={`/${search}`}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            width: 40,
            height: 40,
            display: "flex",
            flex: "0 0 40px",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            marginRight: 20,
            border: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          <ArrowBackIcon style={{ color: "#000" }} />
        </NavLink>
        <div style={{ marginRight: "auto" }}>
          <Typography variant="h4" gutterBottom>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                {candidate.name} {candidate.surname}
              </div>
              <div
                style={{
                  marginLeft: 15,
                  fontSize: 30,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {candidate.recruitmentStatus ===
                "REJECTED_WITH_FUTURE_POTENTIAL" ? (
                  <TrendingUpIcon
                    style={{ color: "rgb(255, 36, 24)", fontSize: 35 }}
                  />
                ) : candidate.recruitmentStatus ===
                  "REJECTED_WITHOUT_FUTURE_POTENTIAL" ? (
                  <TrendingDownIcon
                    style={{ color: "rgb(255, 36, 24)", fontSize: 35 }}
                  />
                ) : candidate.recruitmentStatus === "NEW" ? (
                  <WarningIcon style={{ color: "orange", fontSize: 35 }} />
                ) : candidate.recruitmentStatus ===
                  "ACCEPTED_FOR_THE_FIRST_STAGE" ? (
                  <Filter1Icon style={{ color: "#15b229", fontSize: 35 }} />
                ) : candidate.recruitmentStatus ===
                  "ACCEPTED_FOR_THE_SECOND_STAGE" ? (
                  <Filter2Icon style={{ color: "#15b229", fontSize: 35 }} />
                ) : candidate.recruitmentStatus ===
                  "ACCEPTED_FOR_THE_THIRD_STAGE" ? (
                  <Filter3Icon style={{ color: "#15b229", fontSize: 35 }} />
                ) : candidate.recruitmentStatus === "ACCEPTED_FOR_WORK" ? (
                  <WorkOutlineIcon style={{ color: "#15b229", fontSize: 35 }} />
                ) : candidate.recruitmentStatus ===
                  "INVITED_FOR_THE_FIRST_STAGE" ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Filter1Icon style={{ color: "#5452ff", fontSize: 35 }} />
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 5,
                      }}
                    >
                      <EnvelopeIcon
                        style={{ color: "#5452ff", fontSize: 35 }}
                      />
                    </span>
                  </div>
                ) : candidate.recruitmentStatus ===
                  "RESIGNED_FOR_ANOTHER_JOB_OFFER" ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TrendingUpIcon
                      style={{ color: "rgb(255, 36, 24)", fontSize: 35 }}
                    />
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 5,
                      }}
                    >
                      <WorkOutlineIcon style={{ color: "rgb(255, 36, 24)" }} />
                    </span>
                  </div>
                ) : candidate.recruitmentStatus === "AFTER_THE_FIRST_STAGE" ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Filter1Icon style={{ color: "#15b229", fontSize: 35 }} />
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 5,
                      }}
                    >
                      <AssignmentTurnedInIcon
                        style={{ color: "#15b229", fontSize: 35 }}
                      />
                    </span>
                  </div>
                ) : candidate.recruitmentStatus ===
                  "INFORMED_ABOUT_REJECTION_WITH_FUTURE_POTENTIAL" ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TrendingUpIcon
                      style={{ color: "rgb(255, 36, 24)", fontSize: 35 }}
                    />
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 5,
                      }}
                    >
                      <EnvelopeIcon
                        style={{ color: "rgb(255, 36, 24)", fontSize: 35 }}
                      />
                    </span>
                  </div>
                ) : candidate.recruitmentStatus ===
                  "INFORMED_ABOUT_REJECTION_WITHOUT_FUTURE_POTENTIAL" ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TrendingDownIcon
                      style={{ color: "rgb(255, 36, 24)", fontSize: 35 }}
                    />
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 5,
                      }}
                    >
                      <EnvelopeIcon
                        style={{ color: "rgb(255, 36, 24)", fontSize: 35 }}
                      />
                    </span>
                  </div>
                ) : candidate.recruitmentStatus ===
                  "INVITED_FOR_THE_SECOND_STAGE" ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Filter2Icon style={{ color: "#5452ff", fontSize: 35 }} />
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 5,
                      }}
                    >
                      <EnvelopeIcon
                        style={{ color: "#5452ff", fontSize: 35 }}
                      />
                    </span>
                  </div>
                ) : candidate.recruitmentStatus === "AFTER_THE_SECOND_STAGE" ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Filter2Icon style={{ color: "#15b229", fontSize: 35 }} />
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 5,
                      }}
                    >
                      <AssignmentTurnedInIcon
                        style={{ color: "#15b229", fontSize: 35 }}
                      />
                    </span>
                  </div>
                ) : candidate.recruitmentStatus ===
                  "INVITED_FOR_THE_THIRD_STAGE" ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Filter3Icon style={{ color: "#5452ff", fontSize: 35 }} />
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 5,
                      }}
                    >
                      <EnvelopeIcon
                        style={{ color: "#5452ff", fontSize: 35 }}
                      />
                    </span>
                  </div>
                ) : candidate.recruitmentStatus === "AFTER_THE_THIRD_STAGE" ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Filter3Icon style={{ color: "#15b229", fontSize: 35 }} />
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 5,
                      }}
                    >
                      <AssignmentTurnedInIcon
                        style={{ color: "#15b229", fontSize: 35 }}
                      />
                    </span>
                  </div>
                ) : candidate.recruitmentStatus === "CLOSED_AS_DUPLICATE" ? (
                  <DeleteOutlineIcon />
                ) : (
                  <DeleteOutlineIcon />
                )}
              </div>
            </div>
          </Typography>
          <Typography
            variant="h6"
            style={{ color: "rgba(0,0,0,.5)", fontSize: 18 }}
            gutterBottom
          >
            {candidate.position}
          </Typography>
        </div>
        <ApplicationDropdown id={candidate.id} email={candidate.email} />
      </InlineWrapper>
      <TableContainer className={classes.styledTableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableBody>
            <TableRow>
              <TableCell className={classes.styledTableCell} align="left">
                CV
              </TableCell>
              <TableCell align="left">
                {(candidate.signedLink && (
                  <a
                    style={{
                      color: "inherit",
                      marginLeft: "auto",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                    href={candidate.signedLink}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <DescriptionIcon style={{ marginRight: 5 }} />
                    Open CV
                  </a>
                )) ||
                  "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.styledTableCell} align="left">
                Email
              </TableCell>
              <TableCell align="left">{candidate.email || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.styledTableCell} align="left">
                Phone number
              </TableCell>
              <TableCell align="left">{candidate.phoneNumber || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.styledTableCell} align="left">
                School
              </TableCell>
              <TableCell align="left">{candidate.school || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.styledTableCell} align="left">
                Education
              </TableCell>
              <TableCell align="left">
                {setEducationTitle(candidate.educationStatus)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.styledTableCell} align="left">
                Github URL
              </TableCell>
              <TableCell align="left">
                {candidate.githubUrl ? (
                  <a
                    style={{ color: "inherit" }}
                    href={candidate.githubUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {candidate.githubUrl}
                  </a>
                ) : (
                  "-"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.styledTableCell} align="left">
                Found out from
              </TableCell>
              <TableCell align="left">
                {candidate.foundOutFrom || "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.styledTableCell} align="left">
                Availability start date
              </TableCell>
              <TableCell align="left">
                {candidate.availabilityDate || "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.styledTableCell} align="left">
                Application date
              </TableCell>
              <TableCell align="left">
                {candidate.confirmedAt
                  ? moment(new Date(candidate.confirmedAt)).format("DD-MM-YYYY")
                  : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.styledTableCell} align="left">
                Note
              </TableCell>
              <TableCell align="left">{candidate.note || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.styledTableCell} align="left">
                Accepted Consents
              </TableCell>
              <TableCell align="left">
                <ul style={{ paddingLeft: 15 }}>
                  {candidate.acceptedConsents &&
                    candidate.acceptedConsents.map((consent) => (
                      <li key={consent.id}>{consent.wording}</li>
                    ))}
                </ul>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h5" gutterBottom style={{ margin: "30px 0 20px" }}>
        History
      </Typography>

      <TableContainer className={classes.styledTableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableBody>
            {candidate.recruitmentProcessHistory &&
              candidate.recruitmentProcessHistory.map((historyEntry) => {
                historyEntryKey++;
                return (
                  <TableRow key={historyEntryKey}>
                    <TableCell
                      className={classes.styledTableCell}
                      align="left"
                      style={{ verticalAlign: "text-top" }}
                    >
                      <div>
                        {moment(new Date(historyEntry.createdAt)).format(
                          "DD-MM-YYYY / HH:mm"
                        )}
                      </div>
                      {historyEntry.author && (
                        <div style={{ marginTop: 10, color: "rgba(0,0,0,.4)" }}>
                          {historyEntry.author}
                        </div>
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ verticalAlign: "text-top" }}
                    >
                      {historyEntry.recruitmentStatus && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span
                            style={{
                              marginRight: 10,
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            {historyEntry.recruitmentStatus.charAt(0) +
                              historyEntry.recruitmentStatus
                                .toLowerCase()
                                .replace(/_/g, " ")
                                .slice(1)}
                          </span>
                          {historyEntry.recruitmentStatus ===
                          "REJECTED_WITH_FUTURE_POTENTIAL" ? (
                            <TrendingUpIcon
                              style={{ color: "rgb(255, 36, 24)" }}
                            />
                          ) : historyEntry.recruitmentStatus ===
                            "REJECTED_WITHOUT_FUTURE_POTENTIAL" ? (
                            <TrendingDownIcon
                              style={{ color: "rgb(255, 36, 24)" }}
                            />
                          ) : historyEntry.recruitmentStatus === "NEW" ? (
                            <WarningIcon style={{ color: "orange" }} />
                          ) : historyEntry.recruitmentStatus ===
                            "ACCEPTED_FOR_THE_FIRST_STAGE" ? (
                            <Filter1Icon style={{ color: "#15b229" }} />
                          ) : historyEntry.recruitmentStatus ===
                            "ACCEPTED_FOR_THE_SECOND_STAGE" ? (
                            <Filter2Icon style={{ color: "#15b229" }} />
                          ) : historyEntry.recruitmentStatus ===
                            "ACCEPTED_FOR_THE_THIRD_STAGE" ? (
                            <Filter3Icon style={{ color: "#15b229" }} />
                          ) : historyEntry.recruitmentStatus ===
                            "ACCEPTED_FOR_WORK" ? (
                            <WorkOutlineIcon style={{ color: "#15b229" }} />
                          ) : historyEntry.recruitmentStatus ===
                            "INVITED_FOR_THE_FIRST_STAGE" ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Filter1Icon style={{ color: "#5452ff" }} />
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: 5,
                                }}
                              >
                                <EnvelopeIcon style={{ color: "#5452ff" }} />
                              </span>
                            </div>
                          ) : historyEntry.recruitmentStatus ===
                            "RESIGNED_FOR_ANOTHER_JOB_OFFER" ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <TrendingUpIcon
                                style={{
                                  color: "rgb(255, 36, 24)",
                                }}
                              />
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: 5,
                                }}
                              >
                                <WorkOutlineIcon
                                  style={{ color: "rgb(255, 36, 24)" }}
                                />
                              </span>
                            </div>
                          ) : historyEntry.recruitmentStatus ===
                            "INFORMED_ABOUT_REJECTION_WITH_FUTURE_POTENTIAL" ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <TrendingUpIcon
                                style={{ color: "rgb(255, 36, 24)" }}
                              />
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: 5,
                                }}
                              >
                                <EnvelopeIcon
                                  style={{ color: "rgb(255, 36, 24)" }}
                                />
                              </span>
                            </div>
                          ) : historyEntry.recruitmentStatus ===
                            "INFORMED_ABOUT_REJECTION_WITHOUT_FUTURE_POTENTIAL" ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <TrendingDownIcon
                                style={{ color: "rgb(255, 36, 24)" }}
                              />
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: 5,
                                }}
                              >
                                <EnvelopeIcon
                                  style={{ color: "rgb(255, 36, 24)" }}
                                />
                              </span>
                            </div>
                          ) : historyEntry.recruitmentStatus ===
                            "AFTER_THE_FIRST_STAGE" ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Filter1Icon style={{ color: "#15b229" }} />
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: 5,
                                }}
                              >
                                <AssignmentTurnedInIcon
                                  style={{ color: "#15b229" }}
                                />
                              </span>
                            </div>
                          ) : historyEntry.recruitmentStatus ===
                            "INVITED_FOR_THE_SECOND_STAGE" ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Filter2Icon style={{ color: "#5452ff" }} />
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: 5,
                                }}
                              >
                                <EnvelopeIcon style={{ color: "#5452ff" }} />
                              </span>
                            </div>
                          ) : historyEntry.recruitmentStatus ===
                            "AFTER_THE_SECOND_STAGE" ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Filter2Icon style={{ color: "#15b229" }} />
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: 5,
                                }}
                              >
                                <AssignmentTurnedInIcon
                                  style={{ color: "#15b229" }}
                                />
                              </span>
                            </div>
                          ) : historyEntry.recruitmentStatus ===
                            "INVITED_FOR_THE_THIRD_STAGE" ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Filter3Icon style={{ color: "#5452ff" }} />
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: 5,
                                }}
                              >
                                <EnvelopeIcon style={{ color: "#5452ff" }} />
                              </span>
                            </div>
                          ) : historyEntry.recruitmentStatus ===
                            "AFTER_THE_THIRD_STAGE" ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Filter3Icon style={{ color: "#15b229" }} />
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: 5,
                                }}
                              >
                                <AssignmentTurnedInIcon
                                  style={{ color: "#15b229" }}
                                />
                              </span>
                            </div>
                          ) : historyEntry.recruitmentStatus ===
                            "CLOSED_AS_DUPLICATE" ? (
                            <DeleteOutlineIcon />
                          ) : (
                            <DeleteOutlineIcon />
                          )}
                        </div>
                      )}
                      {historyEntry.recruiterNote && (
                        <pre
                          dangerouslySetInnerHTML={{
                            __html: urlify(historyEntry.recruiterNote),
                          }}
                          style={{
                            padding: "20px 0 10px",
                            whiteSpace: "pre-wrap",
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <form
        noValidate
        autoComplete="off"
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            await editCandidateVersion({
              variables: {
                id: candidate.id,
                data: {
                  recruitmentStatus:
                    recruitmentStatusInput?.current?.value ?? undefined,
                  recruiterNote:
                    recruiterNoteInput?.current?.value ?? undefined,
                },
              },
            });
          } catch (error) {
            console.log(error);
          }
        }}
      >
        <div style={{ margin: "40px 0 30px" }}>
          <FormControl style={{ marginBottom: 30 }} color="secondary">
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              className={classes.formControl}
              id="recruitmentStatus"
              value={recruitmentStatus}
              onChange={handleStatusChange}
              inputRef={recruitmentStatusInput}
              color="secondary"
            >
              {recruitmentStatuses.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="note"
            value={recruiterNote}
            onChange={handleNoteChange}
            label="Recruiter's Note"
            defaultValue={candidate.recruiterNote}
            variant="outlined"
            color="secondary"
            multiline
            rows={3}
            rowsMax={30}
            inputRef={recruiterNoteInput}
            style={{ width: "100%" }}
          />
        </div>
        {formError && (
          <div style={{ color: "rgb(255, 36, 24)", marginBottom: 20 }}>
            {formError}
          </div>
        )}
        <Button
          variant="contained"
          type="submit"
          color="secondary"
          style={{
            display: "block",
            marginLeft: "auto",
          }}
        >
          Zapisz
        </Button>
      </form>
    </StyledContainer>
  );
};

export default Candidate;
