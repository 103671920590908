import {
  TableFooter,
  TableRow,
  TablePagination,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { TablePaginationActions } from './TablePaginationActions';
import { PaginationProps } from '../utils/pagination';

interface TableFooterPaginationProps
  extends Omit<PaginationProps, 'onSortColumn' | 'orderBy' | 'order'> {
  totalRowsQuantity?: number;
}

export const TableFooterPagination: React.FC<TableFooterPaginationProps> = ({
  totalRowsQuantity = 0,
  rowsPerPage,
  page,
  onChangeRowsPerPage,
  onChangePage,
}) => {
  const classes = useStyles();
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          className={classes.styledTablePagination}
          rowsPerPageOptions={[10, 25, 50]}
          component='td'
          count={totalRowsQuantity}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableRow>
    </TableFooter>
  );
};

const useStyles = makeStyles(() => ({
  styledTablePagination: {
    minWidth: 400,
    position: 'fixed',
    right: 0,
    bottom: 0,
    width: 'calc(100vw - 240px)',
    background: '#fff',
    '& .MuiTablePagination-toolbar': {
      paddingRight: 40,
    },
  },
}));
