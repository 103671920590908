import React from "react";
import { TableCell, TableRow, IconButton } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import EnvelopeIcon from "@material-ui/icons/MailOutline";
import WarningIcon from "@material-ui/icons/Warning";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Filter1Icon from "@material-ui/icons/Filter1";
import Filter2Icon from "@material-ui/icons/Filter2";
import Filter3Icon from "@material-ui/icons/Filter3";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import ArrowIcon from "@material-ui/icons/ArrowForward";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import moment from "moment";

export const Candidate: React.FC<{
  id: string;
  name: string;
  surname: string;
  position: string;
  status: string;
  signedLink?: string;
  confirmedAt: string;
  handleClick: any;
}> = ({
  id,
  name,
  surname,
  status,
  position,
  signedLink,
  confirmedAt,
  handleClick,
}) => {
  return (
    <TableRow key={name}>
      <TableCell component="th" scope="row">
        {name}
      </TableCell>
      <TableCell align="left">{surname || "-"}</TableCell>
      <TableCell align="left">
        {signedLink ? (
          <a
            style={{
              color: "inherit",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              width: 40,
              height: 40,
              flex: "0 0 40px",
              backgroundColor: "rgba(0, 0, 0, .3)",
            }}
            href={signedLink}
            rel="noreferrer"
            target="_blank"
          >
            {name.substring(0, name.indexOf(""))}
            <DescriptionIcon style={{ color: "#fff" }} />
          </a>
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell align="left">{position}</TableCell>
      <TableCell align="left">
        {status && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: 10 }}>
              {status.charAt(0) +
                status.toLowerCase().replace(/_/g, " ").slice(1)}
            </span>
            {status === "REJECTED_WITH_FUTURE_POTENTIAL" ? (
              <TrendingUpIcon style={{ color: "rgb(255, 36, 24)" }} />
            ) : status === "REJECTED_WITHOUT_FUTURE_POTENTIAL" ? (
              <TrendingDownIcon style={{ color: "rgb(255, 36, 24)" }} />
            ) : status === "NEW" ? (
              <WarningIcon style={{ color: "orange" }} />
            ) : status === "ACCEPTED_FOR_THE_FIRST_STAGE" ? (
              <Filter1Icon style={{ color: "#15b229" }} />
            ) : status === "ACCEPTED_FOR_THE_SECOND_STAGE" ? (
              <Filter2Icon style={{ color: "#15b229" }} />
            ) : status === "ACCEPTED_FOR_THE_THIRD_STAGE" ? (
              <Filter3Icon style={{ color: "#15b229" }} />
            ) : status === "ACCEPTED_FOR_WORK" ? (
              <WorkOutlineIcon style={{ color: "#15b229" }} />
            ) : status === "INVITED_FOR_THE_FIRST_STAGE" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Filter1Icon style={{ color: "#5452ff" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}
                >
                  <EnvelopeIcon style={{ color: "#5452ff" }} />
                </span>
              </div>
            ) : status === "RESIGNED_FOR_ANOTHER_JOB_OFFER" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TrendingUpIcon style={{ color: "rgb(255, 36, 24)" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}
                >
                  <WorkOutlineIcon style={{ color: "rgb(255, 36, 24)" }} />
                </span>
              </div>
            ) : status === "AFTER_THE_FIRST_STAGE" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Filter1Icon style={{ color: "#15b229" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}
                >
                  <AssignmentTurnedInIcon style={{ color: "#15b229" }} />
                </span>
              </div>
            ) : status === "INVITED_FOR_THE_SECOND_STAGE" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Filter2Icon style={{ color: "#5452ff" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}
                >
                  <EnvelopeIcon style={{ color: "#5452ff" }} />
                </span>
              </div>
            ) : status === "INFORMED_ABOUT_REJECTION_WITH_FUTURE_POTENTIAL" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TrendingUpIcon style={{ color: "rgb(255, 36, 24)" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}
                >
                  <EnvelopeIcon style={{ color: "rgb(255, 36, 24)" }} />
                </span>
              </div>
            ) : status ===
              "INFORMED_ABOUT_REJECTION_WITHOUT_FUTURE_POTENTIAL" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TrendingDownIcon style={{ color: "rgb(255, 36, 24)" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}
                >
                  <EnvelopeIcon style={{ color: "rgb(255, 36, 24)" }} />
                </span>
              </div>
            ) : status === "AFTER_THE_SECOND_STAGE" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Filter2Icon style={{ color: "#15b229" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}
                >
                  <AssignmentTurnedInIcon style={{ color: "#15b229" }} />
                </span>
              </div>
            ) : status === "INVITED_FOR_THE_THIRD_STAGE" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Filter3Icon style={{ color: "#5452ff" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}
                >
                  <EnvelopeIcon style={{ color: "#5452ff" }} />
                </span>
              </div>
            ) : status === "AFTER_THE_THIRD_STAGE" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Filter3Icon style={{ color: "#15b229" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}
                >
                  <AssignmentTurnedInIcon style={{ color: "#15b229" }} />
                </span>
              </div>
            ) : status === "CLOSED_AS_DUPLICATE" ? (
              <DeleteOutlineIcon />
            ) : (
              <DeleteOutlineIcon />
            )}
          </div>
        )}
      </TableCell>
      <TableCell align="left">
        {confirmedAt ? moment(new Date(confirmedAt)).format("DD-MM-YYYY") : "-"}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        style={{ display: "flex", alignItems: "center" }}
      >
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(e) => handleClick(e, id, surname)}
          style={{ marginRight: 10 }}
        >
          <ArrowIcon />
        </IconButton>
        <a
          href={"candidate/" + id}
          style={{ color: "rgba(0, 0, 0, 0.54)" }}
          rel="noopener noreferrer"
          target="_blank"
        >
          <OpenInNewIcon />
        </a>
      </TableCell>
    </TableRow>
  );
};

export default Candidate;
