import { gql, useQuery } from "@apollo/client";
import { CandidateVersion } from "../../Candidates/types";

const CANDIDATE_APPLICATIONS = gql`
  query CandidateApplications($email: String!) {
    candidateApplications(email: $email) {
      id
      confirmedAt
      position
      status
      recruitmentStatus
    }
  }
`;

export function useCandidateApplicationsQuery(email?: string) {
  const { data, loading } = useQuery<{
    candidateApplications: CandidateVersion[];
  }>(CANDIDATE_APPLICATIONS, {
    variables: { email },
    skip: !email
  });
  return { data, loading };
}
