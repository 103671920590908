export const recruitmentStatuses = [
  { value: "NEW", name: "New" },
  {
    value: "ACCEPTED_FOR_THE_FIRST_STAGE",
    name: "Accepted for the first stage",
  },
  { value: "INVITED_FOR_THE_FIRST_STAGE", name: "Invited for the first stage" },
  { value: "AFTER_THE_FIRST_STAGE", name: "After the first stage" },
  {
    value: "REJECTED_WITHOUT_FUTURE_POTENTIAL",
    name: "Rejected without future potential",
  },
  {
    value: "REJECTED_WITH_FUTURE_POTENTIAL",
    name: "Rejected with future potential",
  },
  {
    value: "INFORMED_ABOUT_REJECTION_WITHOUT_FUTURE_POTENTIAL",
    name: "Informed about rejection without future potential",
  },
  {
    value: "INFORMED_ABOUT_REJECTION_WITH_FUTURE_POTENTIAL",
    name: "Informed about rejection with future potential",
  },
  {
    value: "ACCEPTED_FOR_THE_SECOND_STAGE",
    name: "Accepted for the second stage",
  },
  {
    value: "INVITED_FOR_THE_SECOND_STAGE",
    name: "Invited for the second stage",
  },
  { value: "AFTER_THE_SECOND_STAGE", name: "After the second stage" },
  {
    value: "ACCEPTED_FOR_THE_THIRD_STAGE",
    name: "Accepted for the third stage",
  },
  {
    value: "RESIGNED_FOR_ANOTHER_JOB_OFFER",
    name: "Resigned for another job offer",
  },
  { value: "INVITED_FOR_THE_THIRD_STAGE", name: "Invited for the third stage" },
  { value: "AFTER_THE_THIRD_STAGE", name: "After the third stage" },
  { value: "ACCEPTED_FOR_WORK", name: "Accepted for work" },
  { value: "CLOSED_AS_DUPLICATE", name: "Closed as duplicate" },
];
