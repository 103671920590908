import { TextField, TextFieldProps } from "@material-ui/core";

export function DateTimePicker({
  label,
  id,
  name,
  value,
  onChange,
  ...props
}: TextFieldProps) {
  return (
    <TextField
      variant="outlined"
      id={id}
      label={label}
      type="date"
      name={name}
      value={value}
      onChange={onChange}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{ name }}
      {...props}
    />
  );
}
