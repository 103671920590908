import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { SignIn } from "aws-amplify-react";
import Typography from "@material-ui/core/Typography";
import img from "./assets/background.jpg";
import { ISignInProps } from "aws-amplify-react/lib-esm/Auth/SignIn";
import React from "react";
import LoadingButtonWrapper from "./LoadingButtonWrapper";
import { withToast } from "./Toast";
import { withStyles } from '@material-ui/styles';
import { Toolbar, IconButton, AppBar } from '@material-ui/core';
import logo from './assets/logo.png';
import "./App.css";

const styles = () => ({
  loginPageWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '76px 20px 20px',
    backgroundImage: `url(${img})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  description: {
    marginRight: 100,
  },
  styledInput: {
    marginBottom: 20,
  },
  styledButton: {
    height: 56,
    width: '100%',
    letterSpacing: 2,
    marginBottom: 20,
  },
  styledForm: {
    minWidth: 350,
    maxWidth: 350,
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width:480px)': {
      minWidth: 'auto',
      width: '100%',
      maxWidth: '100%',
    },
  }
});
class CustomLogIn extends SignIn {
  constructor(props: ISignInProps) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.signIn = super.signIn.bind(this);
    this.error = this.error.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      this.signIn(event);
    }
  }

  error(error: any) {
    if (typeof error === "string") {
      // @ts-ignore
      this.props.addMessage({ severity: "error", message: error });
    } else if (error.message) {
      // @ts-ignore
      this.props.addMessage({ severity: "error", message: error.message });
    }
  }

  showComponent() {
    const { loading } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.loginPageWrapper}>
        <AppBar color="primary" position="fixed" style={{zIndex: 300}}>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu">
              <img src={logo} alt="logo" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={classes.description}>
          <Typography variant="h5" gutterBottom>
            Welcome to
          </Typography>
          <Typography variant="h5" gutterBottom>
            <b>RD-Projekt Recruitment Panel</b>
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Login, to manage job applications
          </Typography>
        </div>
        <form className={classes.styledForm}>
          <TextField
            variant="outlined"
            type="text"
            inputProps={{
              key: "username",
              name: "username",
            }}
            onChange={this.handleInputChange}
            color="secondary"
            label="EMAIL"
            autoComplete="username"
            className={classes.styledInput}
          />
          <TextField
            id="password"
            variant="outlined"
            type="password"
            inputProps={{
              key: "password",
              name: "password",
            }}
            onChange={this.handleInputChange}
            color="secondary"
            label="PASSWORD"
            autoComplete="current-password"
            onKeyPress={this.onKeyPress}
            className={classes.styledInput}
          />
          <LoadingButtonWrapper loading={!!loading}>
            <Button className={classes.styledButton}
              disabled={!!loading}
              size="large"
              variant="contained"
              color="secondary"
              onClick={this.signIn}
            >
              log in
            </Button>
          </LoadingButtonWrapper>
          {/* <Button
            size="large"
            onClick={() => super.changeState("forgotPassword")}
          >
            reset password
          </Button> */}
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(withToast(CustomLogIn));
