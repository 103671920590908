import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    margin: {
      margin: theme.spacing(1),
    },
  }),
);

export default function CustomizedProgressBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress color="primary" className={classes.margin} />
    </div>
  );
}
