import React from "react";
import CustomizedProgressBar from "../../components/Loader";
import useCandidateQuery from "./useCandidateQuery";
import useEditCandidateMutation from "./useEditCandidateMutation";
import { Candidate as View } from "./View";

function Candidate(props) {
  const { loading: queryLoading, candidate, error } = useCandidateQuery();
  const {
    loading: mutationLoading,
    formError,
    editCandidateVersion
  } = useEditCandidateMutation();

  if (error) {
    console.log(error);
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 20,
          color: "rgb(255, 36, 24)",
          marginTop: 50
        }}
      >
        {error.message || "Unexpected Error"}
      </div>
    );
  }

  if (queryLoading || mutationLoading) {
    return <CustomizedProgressBar />;
  }

  return (
    <View
      {...props}
      candidate={candidate}
      formError={formError}
      editCandidateVersion={editCandidateVersion}
    />
  );
}

export default Candidate;
