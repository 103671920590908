import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useRouteMatch } from "react-router-dom";
import { Routes } from "../../routes";
import { CandidateVersion } from "../Candidates/types";
import { CANDIDATE_FRAGMENT } from "./candidateFragment";

const GET_CANDIDATE = gql`
  ${CANDIDATE_FRAGMENT}
  query candidateVersion($id: ID!) {
    candidateVersion(id: $id) {
      ...Candidate
    }
  }
`;

interface IParams {
  params: { id: string };
}

interface ICandidateData {
  candidate: CandidateVersion[];
}

export interface IWithCandidate extends ICandidateData {}

function useCandidateQuery() {
  const { params } = useRouteMatch(`/${Routes.Candidate}/:id`) as IParams;

  const { loading, error, data } = useQuery(GET_CANDIDATE, {
    variables: { id: params.id }
  });

  const candidate = data?.candidateVersion ?? [];
  return { candidate, loading, error };
}

export default useCandidateQuery;
