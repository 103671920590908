import { Auth } from "aws-amplify";

// eslint-disable-next-line import/prefer-default-export
export async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("error signing out: ", error);
  }
}

export async function getToken() {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (err) {
    // eslint-disable-next-line no-console
    return console.log(err);
  }
}
