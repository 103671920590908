import qs from "qs";
import React from "react";
import { useHistory } from "react-router-dom";

export const ITEMS_PER_PAGE = 10;

export const paginationVariables = ({
  currentPage,
  rowsPerPage
}: {
  currentPage: number;
  rowsPerPage: number;
}) => {
  const first = rowsPerPage || ITEMS_PER_PAGE;
  const offset = (currentPage || 0) * first - first;

  return {
    first,
    offset
  };
};

export interface PaginationProps {
  orderBy: string;
  onSortColumn: (newOrderBy: string) => void;
  order: "desc" | "asc";
  rowsPerPage: number;
  page: number;
  totalRows?: number;
  onChangeRowsPerPage?: (target: any) => void;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    value: number
  ) => void;
  onChangeFilter: (filter?: {}) => void;
  filter: { [key: string]: string | string[] };
}


function useDecodeUrl() {
  const history = useHistory();
  const { rowsPerPage, page, orderBy, order, ...filter } = qs.parse(
    history.location.search,
    { ignoreQueryPrefix: true }
  );
  return {
    rowsPerPage,
    page,
    orderBy,
    order,
    filter,
    history
  };
}

function usePagination(initialOrder: string, initialSortingOrder: "desc" | "asc") {
  const { rowsPerPage, page, orderBy, order, filter, history } = useDecodeUrl();

  const handleSortColumn = (newOrderBy: string) => {
    const newSortingOrder = order === "asc" ? "desc" : "asc";

    const query = qs.stringify({
      orderBy: newOrderBy,
      order: newSortingOrder,
      rowsPerPage
    });
    history.replace({
      ...history.location,
      search: query
    });
  };

  const handleChangeRowsPerPage = ({ target: { value } }: any) => {
    const pagination = {
      rowsPerPage: value,
      page: 0
    };
    const query = qs.stringify(
      { ...pagination, orderBy, ...filter },
      { arrayFormat: "brackets" }
    );
    history.replace({
      ...history.location,
      search: query
    });
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    value: number
  ) => {
    const pagination = {
      rowsPerPage,
      order,
      page: value
    };

    const query = qs.stringify({
      ...pagination,
      orderBy,
      ...filter
    });
    history.replace({
      ...history.location,
      search: query
    });
  };

  const handleSetFilter = (filter?: {}) => {
    const pagination = { rowsPerPage, order, page: 0 };

    const query = qs.stringify({ ...pagination, orderBy, ...filter });
    history.replace({ ...history.location, search: query });
  };

  return {
    onSortColumn: handleSortColumn,
    orderBy: orderBy || initialOrder,
    order: order || initialSortingOrder,
    rowsPerPage: parseInt(String(rowsPerPage || ITEMS_PER_PAGE), 10),
    filter,
    page: parseInt(String(page || 0), 10),
    onChangeRowsPerPage: handleChangeRowsPerPage,
    onChangeFilter: handleSetFilter,
    onChangePage: handleChangePage
  } as PaginationProps;
}

export default usePagination;
