import { IconButton } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { client } from "../gqlClient";
import { signOut } from "../utils/authorization";

export default function LogoutButton() {
  function handleLogOut() {
    setTimeout(() => {
      try {
        signOut();
        client.clearStore();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }, 200);
  }

  return (
    <div style={{marginLeft: "auto"}}>
      <IconButton onClick={handleLogOut}>
        <ExitToAppIcon style={{ color: "#fff" }} />
      </IconButton>
    </div>
  );
}
