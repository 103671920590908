import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { addMessage } from "./redux/actions";
import store from "./redux/store";
import { getToken, signOut } from "./utils/authorization";

const errorsLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // eslint-disable-next-line no-console
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      store.dispatch(addMessage({ severity: "error", message }));
    });
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
    // @ts-ignore
    if (networkError.statusCode === 401) {
      signOut();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      client.clearStore();
    }
  }
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getToken();
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const httpLink = new HttpLink({ uri: `${process.env.REACT_APP_API_URL}/graphql` });

// eslint-disable-next-line import/prefer-default-export
export const client = new ApolloClient({
  link: from([authLink, errorsLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
  cache: new InMemoryCache({
    typePolicies: {
      WorkspaceToUser: {
        keyFields: ["userId", "workspaceId"],
      },
      // Query: {
      //   fields: {
      //     report: {
      //       read() {
      //         return reportMock;
      //       },
      //     },
      //   },
      // },
    },
  }),
  // typeDefs,
});
