import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  styledContainer: {
    padding: 40,
    '@media (max-width:480px)': {
      padding: "40px 20px",
    },
  },
});

export const StyledContainer: React.FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.styledContainer}>{children}</div>;
};

export default StyledContainer;
