import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles
} from "@material-ui/core";
import { TableLabels, HeadCellProps } from "./TableLabels";
import { TableFooterPagination } from "./TablePagination";
import { PaginationProps } from "../utils/pagination";

interface TableViewProps extends PaginationProps {
  headCells: HeadCellProps[];
  tableBody?: JSX.Element[];
  totalRowsQuantity?: number;
}

export const TableView: React.FC<TableViewProps> = ({
  headCells,
  orderBy,
  onSortColumn,
  order,
  tableBody,
  filter,
  onChangeFilter,
  ...paginationProps
}) => {
  const classes = useStyles();
  return (
    <TableContainer className={classes.styledTableContainer}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableLabels
              headCells={headCells}
              orderBy={orderBy}
              onSortColumn={onSortColumn}
              order={order}
              filter={filter}
              onChangeFilter={onChangeFilter}
            />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>{tableBody}</TableBody>
        <TableFooterPagination
          filter={filter}
          onChangeFilter={onChangeFilter}
          {...paginationProps}
        />
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles(() => ({
  styledTableContainer: {
    maxHeight: "calc(100vh - 250px)"
  }
}));

export default TableView;
