import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import { styled } from "@material-ui/core/styles";
import React from "react";
import { useCandidateApplicationsQuery } from "./useCandidateApplicationsQuery";
import { useHandleClick } from "./useHandleClick";
import { recruitmentStatuses } from "../../../constants/recruitmentStatuses";

const StyledMenuItem = styled(MenuItem)({
  borderBottom: "1px solid #e6e6e6",
  "&:last-of-type": {
    borderBottom: "none",
  },
});

function ApplicationDropdown({ email, id }: { email?: string; id: string }) {
  const { data, loading } = useCandidateApplicationsQuery(email);
  const [handleClick] = useHandleClick();

  if (
    loading ||
    data.candidateApplications.length === 0 ||
    data.candidateApplications.length === 1
  ) {
    return null;
  }

  return (
    <Select variant="outlined" color="primary" value={id} onChange={handleClick}>
      {data
        ? data.candidateApplications.map((application) => (
            <StyledMenuItem key={application.id} value={application.id}>
              <Grid container direction="column" alignItems="flex-start">
                <Grid item container justify="space-between">
                  <Grid item>{application.position}</Grid>
                  <Grid item>
                    {new Date(application.confirmedAt).toLocaleDateString()}
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="caption" color="primary">
                    {
                      recruitmentStatuses.find(
                        (status) =>
                          status.value === application.recruitmentStatus
                      ).name
                    }
                  </Typography>
                </Grid>
              </Grid>
            </StyledMenuItem>
          ))
        : null}
    </Select>
  );
}

export default ApplicationDropdown;
