import React, { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { RecruitmentStatusPicker } from "./RecruitmentStatusPicker";
import { DateTimePicker } from "./DatePicker";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  styledInput: {
    "@media (max-width:500px)": {
      width: "100% !important",
    },
  },
});

interface FilterFields {
  nameSurname: string | undefined;
  status: string[];
  dateFrom: string | undefined;
  dateTo: string | undefined;
}

const initialState: FilterFields = {
  nameSurname: "",
  status: [],
  dateFrom: "",
  dateTo: "",
};

export default function CandidatesFilter({ onChangeFilter, filter }: any) {
  const [filtersState, setFiltersState] = useState(initialState);

  useEffect(() => {
    const isConfirmedValid =
      Array.isArray(filter.confirmedAt) && filter.confirmedAt.length === 2;
    const newState = {
      nameSurname: filter.nameSurname,
      status: Array.isArray(filter.status)
        ? filter.status
        : filter.status
        ? [filter.status]
        : [],
      dateFrom: isConfirmedValid
        ? moment(filter.confirmedAt[0]).format("yyyy-MM-DD")
        : "",
      dateTo: isConfirmedValid
        ? moment(filter.confirmedAt[1]).format("yyyy-MM-DD")
        : "",
    };
    setFiltersState(newState);
  }, [filter]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFiltersState({
      ...filtersState,
      [event.target.name]: event.target.value,
    });
  }

  function onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      submitFilters();
    }
  }

  function submitFilters() {
    const newFilter = Object.assign(
      {},
      filtersState.nameSurname
        ? { nameSurname: filtersState.nameSurname }
        : null,
      filtersState.status ? { status: filtersState.status } : null,
      filtersState.dateFrom || filtersState.dateTo
        ? {
            confirmedAt: [
              new Date(filtersState.dateFrom || "01-01-2000"),
              filtersState.dateTo ? new Date(filtersState.dateTo) : new Date(),
            ],
          }
        : null
    );
    onChangeFilter(newFilter);
  }

  function resetFilters() {
    onChangeFilter({});
    setFiltersState(initialState);
  }

  const classes = useStyles();

  return (
    <Grid container justify="center" spacing={3} alignItems="center">
      <Grid item className={classes.styledInput}>
        <TextField
          id="nameSurname"
          variant="outlined"
          inputProps={{
            key: "nameSurname",
            name: "nameSurname",
          }}
          onChange={handleChange}
          label="Name"
          autoComplete="off"
          value={filtersState.nameSurname}
          onKeyPress={onKeyPress}
          className={classes.styledInput}
          color="secondary"
        />
      </Grid>
      <Grid item className={classes.styledInput}>
        <RecruitmentStatusPicker
          onChange={handleChange}
          variant="outlined"
          value={filtersState.status}
          name="status"
          onKeyPress={onKeyPress}
          className={classes.styledInput}
          color="secondary"
        />
      </Grid>
      <Grid item className={classes.styledInput}>
        <DateTimePicker
          label="Date from"
          name="dateFrom"
          id="date-from-picker"
          value={filtersState.dateFrom}
          onChange={handleChange}
          className={classes.styledInput}
          color="secondary"
        />
      </Grid>
      <Grid item className={classes.styledInput}>
        <DateTimePicker
          label="Date to"
          name="dateTo"
          id="date-to-picker"
          value={filtersState.dateTo}
          onChange={handleChange}
          className={classes.styledInput}
          color="secondary"
        />
      </Grid>
      <Grid item>
        <Grid container wrap="nowrap" spacing={2} justify="flex-end">
          <Grid item>
            <Button
              onClick={resetFilters}
              variant="outlined"
              color="secondary"
              size="large"
              style={{ height: 56, width: 105 }}
            >
              RESET
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={submitFilters}
              variant="contained"
              size="large"
              color="secondary"
              style={{
                height: 56,
                width: 105,
              }}
            >
              SEARCH
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
